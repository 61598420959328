import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BlogPost from "../components/blogPost";

const uri = "/about/";

const AboutPage = ({ location }) => {
  const searchQuery = location.state?.searchQuery;

  return (
    <Layout pageInfo={{ pageName: "about", searchQuery: searchQuery }}>
      <Seo title="About" keywords={[`recipes`, `about`]} description="About page" url={uri} />
      <Container fluid>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>About</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <BlogPost title="About ILoveItCamping.com" adCount={0}>
          <Container fluid>
            <Row className="d-flex flex-wrap">
              <h3 className="mb-3">Who are we?</h3>
              <p>We are a family from Minnesota that enjoys cooking, camping, and sharing recipes! </p>
              <h3 className="mb-3">Where are these recipes from?</h3>
              <p>
                These recipes have been gathered from family and friends over many years. There are some great recipes
                here, but also some super simple ones in order to give you a comprehensive list.
              </p>
              <h3 className="mb-3">How can you contact us?</h3>
              <p>info@ILoveItRecipes.com</p>
              <h3 className="mb-3">Do we have a privacy policy and terms of conditions?</h3>
              <p>We thought you'd never ask. Here they are:</p>
              <ul className="ms-4">
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms and Conditions</Link>
                </li>
              </ul>
            </Row>
          </Container>
        </BlogPost>
      </Container>
    </Layout>
  );
};

export default AboutPage;
